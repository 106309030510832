export const queryFragmentSeo = {
  seo: {
    fields: '*',
    populate: {
      image: true
    }
  }
}

export const queryFragmentFirstRichTextBlock = {
  'blocks.rich-text': {
    fields: ['richText'],
    filters: {
      richText: {
        $notNull: true
      }
    }
  }
}

export const queryFragmentImagesBlocks = {
  'blocks.images': {
    populate: {
      files: {
        populate: true
      }
    }
  }
}
